<template>
  <v-container fluid>
    <v-form ref="form" @submit.prevent="validateBeforeSubmit">
      <v-col cols="12">
        <v-select
          v-model="vm.existenceOfCarer"
          :items="existenceOfCarer"
          label="Existence of Carer"
          multiple
        ></v-select>
      </v-col>

      <v-col cols="12">
        <v-select
          v-model="vm.relationshipOfClientToCarer"
          :items="relationshipOfClientToCarer"
          label="Relationship of participant to carer"
        ></v-select>
      </v-col>

      <v-col cols="12">
        <v-radio-group
          v-model="vm.doesCarerAssistServiceUser"
          label="Does the Carer assist the service user in the areas of self care, mobility or communication?"
        >
          <v-radio label="Yes" v-bind:value="true"></v-radio>
          <v-radio label="No" v-bind:value="false"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12">
        <v-text-field v-model="vm.nameOfCarer" label="Name of Carer" required></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-select
          v-model="vm.carerResidencyStatus"
          :items="carerResidencyStatus"
          label="Carer Residency Status"
        ></v-select>
      </v-col>
      <v-col cols="12">
        <v-select v-model="vm.carerAgeBracket" :items="carerAgeBracket" label="Carer Age Bracket"></v-select>
      </v-col>

      <v-col cols="12">
        <v-select
          v-model="vm.domesticLife"
          :items="domesticLife"
          label="Domestic Contribution by Carer"
        ></v-select>
      </v-col>
      <v-btn color="primary" type="submit">Continue</v-btn>
    </v-form>
  </v-container>
</template>


<script>
var Models = require("./models.js");
import Vue from "vue";
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);
export default {
  $_veeValidate: {
    validator: "new"
  },
  name: "carer-form",
  model: new Models.CarerDetails(),
  data: () => ({
    vm: new Models.CarerDetails(),
    existenceOfCarer: ["Part Time", "Full time", "Parental", "External"],

    relationshipOfClientToCarer: [
      "Mother",
      "Father",
      "Husband / Male Partner",
      "Wife / Female Partner",
      "Other Male Relative",
      "Other Female Relative",
      "Sister",
      "Brother",
      "Paid Carer",
      "Other"
    ],
    carerResidencyStatus: ["Non Resident Carer", "Co-Resident Carer"],
    carerAgeBracket: [
      "Under 15 years",
      "15 - 24",
      "25 - 44",
      "45 - 64",
      "Over 65"
    ],
    domesticLife: ["None", "Low", "Medium", "High"],
    form: {}
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
  },
  computed: {
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
    init() {
      this.vm =
        this.$store.getters.currentCase == undefined ||
        this.$store.getters.currentCase.carerDetails == undefined
          ? {}
          : this.$store.getters.currentCase.carerDetails;
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.currentCase.carerDetails = this.vm;
          this.$store.dispatch("setCurrentCase", this.currentCase);
          this.$emit("next-step");
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

